<template>
  <div>
    <UIExit label="EXIT PICKUP MODE" />
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import layoutConstants from '@src/constants/layout-constant.js'
import UIExit from '@src/components/UiElements/UIExit.vue'

export default {
  components: {
    UIExit,
  },
  created() {
    this.setLeftbarContent({
      back: '',
      customeHeader: 'left-bar-class-list',
      SHOW_SECTION_DROPDOWN_TO: layoutConstants.showSectionDropdownTo,
      SHOW_CLASS_DROPDOWN_TO: layoutConstants.showClassDropdownTo,
      routes: [
        {
          id: '0',
          role: ['section_teacher', 'staff'],
          name: 'LB_AR',
          route: { name: 'support-user-active-request' },
        },

        {
          id: '2',
          role: ['section_teacher', 'staff'],
          name: 'LB_ALL_R',
          route: { name: 'support-user-all-requests' },
        },
      ],
    })
    localStorage.setItem('redirectTo', 'my-profile')
  },
  methods: {
    ...mapActions('layout', ['setLeftbarContent']),
  },
}
</script>
